import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b31e25d4")
const _hoisted_1 = { class: "ppt-view" }
_popScopeId()

import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import store from "@/store/index";

import { loadMicroApp } from "qiankun";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { eventCenter, event } from "@evideo/frontend-utils";
import logger from "@evideo/logger";

import servers from "@/config/servers";
import config from "@/config/app.config";


export default _defineComponent({
  props: {
  url: {
    type: String,
    required: true,
  },
},
  emits: ["goBack"],
  setup(__props, { expose, emit: emits }) {

const props = __props



const pptContainer = ref();
const pptUrl = ref(props.url);
const pptApp = ref<any>(null);
const fullScreen = ref(false);

const loadPptTimeId = ref();
const pptLoading = ref(true);
const pptLoadTxt = ref("正在加载ppt模块，首次可能会慢点，请稍后...");

watch(
  () => props.url,
  (newVal, oldVal) => {
    if (newVal && newVal.slice(-5) === ".json") {
      pptUrl.value = newVal;
      loadPpt();
    }
    if (!newVal && oldVal.slice(-5) === ".json") {
      pptUrl.value = newVal;
      resetPpt();
    }
  }
);
const resetPpt = () => {
  if (pptApp.value) {
    eventCenter.send("PPTIST_RESET_PPT");
    logger.info("通知清空重置Ppt");
  }
};
const loadPpt = () => {
  loadPptTimeId.value = setInterval(() => {
    if (!pptLoading.value && pptApp.value) {
      eventCenter.send(event.PPTIST_LOAD_PPT, { url: pptUrl.value });
      clearInterval(loadPptTimeId.value);
    }
  }, 100);
};
const loadPPTApp = async () => {
  const env = JSON.stringify(process.env.RELEASE_ENV);
  let entryUrl = config[servers.pptist];
  if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
    entryUrl = localStorage.entryUrl;
  }
  logger.info("ppt app entry url:", entryUrl);
  eventCenter.sub(`teachPptPC-load-ok`, () => {
    pptLoading.value = false;
    eventCenter.sub(event.COMMON_FULL_SCREEN, (res: any) => {
      if (res.fullScreen) {
        fullScreen.value = true;
      } else {
        fullScreen.value = false;
      }
    });
    eventCenter.sub("PPT_STOP_PLAY", () => {
      goBack();
    });
  });

  logger.info("ppt-view-token", store.state.token);
  pptApp.value = await loadMicroApp(
    {
      name: "teachPptPC",
      entry: entryUrl,
      container: "#ppt-view-container",
      props: {
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          runtime: {
            playPptRule: {
              play: "demonstrate",
              stop: "preview",
            },
            state: {
              screenType: "demonstrate",
              screening: true,
            },
          },
          os: store.state.hardwareModel,
        },
        micrAppUrl: entryUrl,
      },
    },
    {
      sandbox: { experimentalStyleIsolation: true },
      $$cacheLifecycleByAppName: true,
    }
  );
};

const goBack = () => {
  if (loadPptTimeId.value) {
    clearInterval(loadPptTimeId.value);
  }
  emits("goBack");
};
const reset = () => {
  // fixme: 关闭ppt，需要直接关闭画笔，无论有没有开启
  if (window.require) {
    const ipcRenderer = window.require("electron").ipcRenderer;
    ipcRenderer.sendToHost("closePencil");
  }
  // 通知PPT隐藏事件，停止声音播放等
  eventCenter.send("ON_PPT_HIDE");
};

expose({ reset });
onMounted(async () => {
  await loadPPTApp();
});

onBeforeUnmount(async () => {
  if (pptApp.value) {
    if (pptApp.value.preUnmount) {
      await pptApp.value.preUnmount();
    }
    pptApp.value.unmount();
    logger.info("卸载成功");
  }
  if (loadPptTimeId.value) {
    clearInterval(loadPptTimeId.value);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", { class: "ppt-loading" }, _toDisplayString(pptLoadTxt.value), 513), [
      [_vShow, pptLoading.value]
    ]),
    _createElementVNode("div", {
      id: "ppt-view-container",
      ref: pptContainer,
      class: "ppt-container"
    }, null, 512)
  ]))
}
}

})