
import { defineComponent } from "vue";
export default defineComponent({
  name: "sing-play-confirm",
  setup(props, { emit }) {
    const close = () => {
      emit("onCancel");
    };
    const open = () => {
      emit("onOk");
    };
    const closeDialog = () => {
      emit("close");
    };
    return {
      close,
      open,
      closeDialog,
    };
  },
});
