import logger from "@evideo/logger";
import { ref, toRaw } from "vue";

declare global {
  interface Window {
    require?: any;
  }
}
interface PageCanvasSize {
  canvasWidth: number | string;
  canvasHeight: number | string;
  containerWidth: number | string;
  containerHeight: number | string;
}
let ipcRenderer: any;

if (window.require) {
  ipcRenderer = window.require("electron").ipcRenderer;
}
let isPlay = false;
// 手的状态
let handShow = false;
// 笔的状态
const pencilState = ref(false);

export default () => {
  const windowInnerWidth = window.innerWidth;
  const windowInnerHeight = window.innerHeight;

  const defaultSize: PageCanvasSize = {
    containerWidth: windowInnerWidth,
    containerHeight: windowInnerHeight - 80,
    canvasWidth: windowInnerWidth,
    canvasHeight: windowInnerHeight - 80,
  };

  const pageCanvasSize = ref<PageCanvasSize | undefined>({ ...defaultSize });
  // 与electron进程通信
  const ipcRenderSendToHost = (channel: string, ...args: any[]) => {
    logger.info(`ipcRenderer.sendToHost 被调用;key:${channel}`, args);
    try {
      ipcRenderer.sendToHost(channel, args);
    } catch (err) {
      logger.info(`${channel}发送失败`, err);
    }
  };
  // electron重新打开笔时再次设置状态
  const reOpenPencil = () => {
    sendSvgHeight();
    ipcRenderSendToHost("setCanvasPPT", [
      { pageCanvasSize: toRaw(pageCanvasSize.value) },
    ]);
  };
  // 接收开启笔信息，并重新传送高度
  const handlePencilState = () => {
    try {
      ipcRenderer.on("setPencil", (event: any, message: boolean) => {
        pencilState.value = message;
        if (!pencilState.value) {
          handShow = false;
        }
        logger.info(`pencil _pencil state: ${pencilState.value}`, message);

        if (message) {
          reOpenPencil();
        } else {
          pageCanvasSize.value = undefined;
        }
      });
    } catch (err) {
      logger.info(`openPencil发送失败`, err);
    }
  };
  // 设置曲谱的宽高尺寸
  const sendSvgHeight = () => {
    const dom = document.getElementById("paper");
    if (dom) {
      const canvasHeight = dom.scrollHeight;
      const containerHeight = dom.offsetHeight;
      const canvasWidth = dom.clientWidth;
      const containerWidth = dom.clientWidth;
      pageCanvasSize.value = {
        containerWidth: containerWidth,
        containerHeight: containerHeight,
        canvasWidth: canvasWidth,
        canvasHeight: canvasHeight,
      };
      ipcRenderSendToHost("isMusicList", [
        {
          pageCanvasSize: toRaw(pageCanvasSize.value),
        },
      ]);
    }
  };
  // 传送曲谱当前滚动位置
  const sendCurrentTop = (top: number, isPlay: boolean, isFirst: boolean) => {
    ipcRenderSendToHost("setCurrentTop", {
      top,
      isPlay,
      isFirst,
    });
  };
  /**
   * 处理曲谱滚动，对于笔的画布滚动
   * @param scrollTop
   */
  const handleSingPlayScroll = (
    scrollTop: number,
    _isPlay: boolean,
    isFirst = false
  ) => {
    isPlay = _isPlay;
    sendCurrentTop(scrollTop, isPlay, isFirst);
  };
  // electron通知曲谱进行滚动
  const CanvasScrollOn = () => {
    const dom = document.querySelector<HTMLElement>("#paper");
    try {
      ipcRenderer.on("canvasScroll", (event: any, message: any) => {
        if (dom && !handShow && !isPlay) {
          dom.scrollTop = message[0];
        }
      });
    } catch (err) {
      logger.info(`canvasScroll发送失败`, err);
    }
  };
  // electron通知手的状态
  const handShowState = () => {
    try {
      ipcRenderer.on("handState", (event: any, message: any) => {
        handShow = message;
        logger.info(`pencil hand state: ${handShow}`);
      });
    } catch (err) {
      logger.info(`handShowState发送失败`, err);
    }
  };
  const changeIsPlay = (_isPlay: boolean) => {
    isPlay = _isPlay;
  };

  return {
    handlePencilState,
    pencilState,
    sendSvgHeight,
    handleSingPlayScroll,
    CanvasScrollOn,
    handShowState,
    changeIsPlay,
  };
};
