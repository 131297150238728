
import { computed, defineComponent, ref, PropType, watch } from "vue";
export default defineComponent({
  name: "video-control",
  props: {
    status: {
      type: String as PropType<"play" | "pause" | "stop">,
      default: "",
    },
    duration: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
  },
  emits: ["play", "stop", "set-time"],
  setup(props, ctx) {
    const status = ref(props.status);
    watch(
      () => props.status,
      (newVal) => {
        status.value = newVal;
      }
    );
    const play = () => {
      if (status.value === "play") {
        status.value = "pause";
      } else {
        status.value = "play";
      }
      ctx.emit("play", status.value);
    };
    const stop = () => {
      status.value = "stop";
      ctx.emit("stop", status.value);
    };
    const prefixZero = (num: string | number, n: number) => {
      return (Array(n).join("0") + num).slice(-n);
    };

    const curentMinutes = computed(() => {
      const seconds = props.currentTime;
      if (!seconds) {
        return "00:00";
      }
      const m = parseInt(`${seconds / 60}`);
      const s = parseInt(`${seconds % 60}`);
      return `${prefixZero(m, 2)}:${prefixZero(s, 2)}`;
    });

    const totalMinutes = computed(() => {
      const seconds = props.duration;
      if (!seconds) {
        return "--:--";
      }

      const m = parseInt(`${seconds / 60}`);
      const s = parseInt(`${seconds % 60}`);
      return `${prefixZero(m, 2)}:${prefixZero(s, 2)}`;
    });

    watch(
      () => props.currentTime,
      (newVal) => {
        newVal === props.duration && stop();
      }
    );

    const percent = computed(() => {
      return (props.currentTime / props.duration) * 100;
    });

    const onSetTime = (e: any) => {
      ctx.emit("set-time", e.target.value);
    };

    return {
      play,
      stop,
      curentMinutes,
      totalMinutes,
      onSetTime,
      percent,
    };
  },
});
