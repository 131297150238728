import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-76ab0b56")
const _hoisted_1 = { class: "video-control" }
const _hoisted_2 = { class: "process-bar" }
const _hoisted_3 = { class: "minutes" }
const _hoisted_4 = { class: "left-minutes" }
const _hoisted_5 = { class: "right-minutes" }
const _hoisted_6 = { class: "process" }
const _hoisted_7 = ["max", "value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["control-btn", _ctx.status === 'play' ? 'pause' : 'play']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args)))
    }, null, 2),
    _createElementVNode("div", {
      class: "control-btn stop",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.curentMinutes), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.totalMinutes), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("input", {
          type: "range",
          min: "0",
          max: _ctx.duration,
          step: "0.1",
          value: _ctx.currentTime,
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSetTime && _ctx.onSetTime(...args))),
          style: _normalizeStyle({ backgroundSize: `${_ctx.percent}% 100%` })
        }, null, 44, _hoisted_7)
      ])
    ])
  ]))
}