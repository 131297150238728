// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/courseware-card/close-icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sing-play-confirm-mask{position:fixed;top:0;left:0;width:100vw;height:100vh;display:flex;justify-content:center;align-items:center;z-index:1;background:rgba(0,0,0,.4)}.sing-play-confirm-mask .sing-play-confirm-modal{width:730px;height:330px;padding:25px 30px;font-size:16px;position:relative;border:15px solid #ffcc7b;border-radius:40px;background-color:#fff}.sing-play-confirm-mask .sing-play-confirm-modal .close-tool-tip{position:absolute;width:22px;height:22px;top:25px;right:25px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;cursor:pointer}.sing-play-confirm-mask .sing-play-confirm-modal .content{height:150px;padding-top:20px;font-size:30px;color:#764b19;display:flex;justify-content:center;align-items:center}.sing-play-confirm-mask .sing-play-confirm-modal .btns{font-size:30px;height:140px;padding:0 20px;align-items:center;display:flex;justify-content:space-between;font-weight:700}.sing-play-confirm-mask .sing-play-confirm-modal .btns .ok{text-shadow:2px 2px 12px #449aea;-webkit-text-stroke:1.2px #449aea;background-image:linear-gradient(0deg,#33a1ff,#5bbbff),linear-gradient(#ff850d,#ff850d);background-blend-mode:normal,normal}.sing-play-confirm-mask .sing-play-confirm-modal .btns .cancel,.sing-play-confirm-mask .sing-play-confirm-modal .btns .ok{cursor:pointer;width:230px;height:72px;border-radius:2.2rem;text-align:center;line-height:40px;font-family:SourceHanSansCN-Regular;font-stretch:normal;color:#fff;display:flex;align-items:center;justify-content:center}.sing-play-confirm-mask .sing-play-confirm-modal .btns .cancel{text-shadow:2px 2px 12px #0cc187;-webkit-text-stroke:1.2px #0cc187;background-image:linear-gradient(0deg,#00c88d,#12df9a)}.sing-play-confirm-mask .sing-play-confirm-modal .btns .ok:hover{background-image:linear-gradient(0deg,#217cff,#3d9dff)}.sing-play-confirm-mask .sing-play-confirm-modal .btns .cancel:hover{background-image:linear-gradient(0deg,#00bc7a,#0ed888)}", ""]);
// Exports
module.exports = exports;
